import React, { useEffect } from 'react';
import './App.css';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LaunchIcon from '@mui/icons-material/Launch';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const backendUrl = "https://internal-tools-api.prod.lnkup.cloud";

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

interface User {
  name: string;
  email: string;
  profileLink: string;
  registrationDate: string;
  status: number;
}

interface UserTableProps {
  list: User[];
  updateNotifier: React.Dispatch<React.SetStateAction<User[]>>;
}

interface Group {
  name: string;
  owner: string;
  link: string;
  memberCount: string;
  creationDate: string;
}

interface GroupTableProps {
  list: Group[];
}

function ActivateUser(email: string): boolean {
  // let formData = new FormData();
  // formData.append('user_email', email);
  let usp = new URLSearchParams();
  usp.set('user_email', email)

  fetch(backendUrl + "/ActivateUser", {
    method: 'POST', 
    body: usp,
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error("Failed");
    }
    alert("Activated");
    return true;
  })
  .catch(() => {
    alert("Failed to activate");
    return false;
  });

  return true;
}

function UsersTable({ list, updateNotifier }: UserTableProps) {
  return (<TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Registered on</TableCell>
          <TableCell>Activation</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map((entry: any) => (
          <TableRow
            key={entry.email}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                {entry.name}&nbsp;
                <a href={entry.profileLink} target="_blank" rel="noreferrer noopener">
                  <LaunchIcon fontSize="small" />
                </a>
              </div>
            </TableCell>
            <TableCell>{entry.email}</TableCell>
            <TableCell>{entry.registrationDate}</TableCell>
            <TableCell>
              {
                entry.status === 0 ?
                <Button onClick={() => {
                  if (!ActivateUser(entry.email)) {
                    return;
                  }

                  const newList = list.map((item) => {
                    if (item.email === entry.email) {
                      const updatedItem = {
                        ...item,
                        status: 1,
                      };
              
                      return updatedItem;
                    }
              
                    return item;
                  });
              
                  updateNotifier(newList);
                }}>Activate</Button>
                : <Button disabled>Activated</Button>
              }
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>);
}

function GroupsTable({ list }: GroupTableProps) {
  return (<TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Owner</TableCell>
          <TableCell>Member count</TableCell>
          <TableCell>Created on</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map((entry: any) => (
          <TableRow
            key={entry.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                {entry.name}&nbsp;
                <a href={entry.link} target="_blank" rel="noreferrer noopener">
                  <LaunchIcon fontSize="small" />
                </a>
              </div>
            </TableCell>
            <TableCell>
            <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                {entry.owner}&nbsp;
                <a href={entry.ownerProfileLink} target="_blank" rel="noreferrer noopener">
                  <LaunchIcon fontSize="small" />
                </a>
              </div>
            </TableCell>
            <TableCell>{entry.memberCount}</TableCell>
            <TableCell>{entry.creationDate}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>);
}

function App() {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [userList, setUserList] = React.useState([] as any);
  const [groupList, setGroupList] = React.useState([]);
  const [stats, setStats] = React.useState({usersRegisteredLastDay: 0, usersRegisteredLastWeek: 0, usersRegisteredTotal: 0, groupsCreatedLastDay: 0, groupsCreatedLastWeek: 0, groupsCreatedTotal: 0, dau: 0, mau: 0});

  useEffect(() => {
    fetch(backendUrl + "/GetRecentUsers")
      .then((response) => response.json())
      .then((data) => {
        setUserList(data);
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);

  useEffect(() => {
    fetch(backendUrl + "/GetRecentGroups")
      .then((response) => response.json())
      .then((data) => {
        setGroupList(data);
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);

  useEffect(() => {
    fetch(backendUrl + "/GetStats")
      .then((response) => response.json())
      .then((data) => {
        setStats(data);
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const exportUsers = () => {
    window.open(backendUrl + "/ExportUsers", "_self");
  }

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleChange}>
          <Tab label="Recent users" />
          <Tab label="Recent groups" />
        </Tabs>
      </Box>
      <CustomTabPanel value={selectedTab} index={0}>
      <Button sx={{ float: 'right', backgroundColor: 'rgb(25, 118, 210)', color: 'white' }} onClick={exportUsers}>Export</Button>
        <h3>Users registered: last day - {stats.usersRegisteredLastDay}, last week - {stats.usersRegisteredLastWeek}, grand total - {stats.usersRegisteredTotal}</h3>
        <h3>Users active: daily - {stats.dau}, monthly - {stats.mau}</h3>
        <UsersTable list={userList} updateNotifier={setUserList} />
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <h3>Groups created: last day - {stats.groupsCreatedLastDay}, last week - {stats.groupsCreatedLastWeek}, grand total - {stats.groupsCreatedTotal}</h3>
        <GroupsTable list={groupList} />
      </CustomTabPanel>
    </Box>
  );
}

export default App;
